<template>

    <main class="main">
        <v-dialog v-model="dialogEditar" width="1000">
            <v-card>
                <v-card-title>
                    Alteração de Metas
                    <v-spacer />
                    <v-btn class="mx-2" elevation="0" color="error" @click="cancelar()">Cancelar</v-btn>
                    <v-btn elevation="0" color="primary" @click="editar()">Salvar</v-btn>
                </v-card-title>
                <v-divider class="my-4"></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-text-field v-model.number="metaAtualizar.idmeta" type="number" outlined dense label="Cód. Meta"
                                class="mb-n4" @change="get()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-bind:disabled="estaDesabilitado" v-model.number="metaAtualizar.idempresa"
                                :items="empresasTitulo" item-text="filial" item-value="idfilial" outlined dense
                                label="Cód. Empresa" autofocus class="mb-n5"></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select v-bind:disabled="estaDesabilitado" v-model.number="metaAtualizar.idservico"
                                :items="servicos" item-text="servico" item-value="idservico" outlined dense
                                label="Cód. Serviço" autofocus class="mb-n5"></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field v-model.number="metaAtualizar.valmeta" type="number" outlined dense
                                label="Valor da meta (R$)" class="mb-n4"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select v-model.number="metaAtualizar.flaginativo" :items="statusMeta" item-text="status"
                                item-value="idstatus" outlined dense label="Status Meta" autofocus
                                class="mb-n5"></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <InputDatePicker v-model="metaAtualizar.dtini" label="Data inicial" :outlined="true" :dense="true" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <InputDatePicker v-model="metaAtualizar.dtfim" label="Data final" :outlined="true" :dense="true" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-card>
            <v-dialog v-model="dialogErro.status" persistent>
                <CardDialog :dialog="dialogErro" />
            </v-dialog>
            <v-card-text>
                <v-card-title class="title-servicos primary--text">
                    Cadastro de Metas
                    <v-spacer />
                    <v-btn class="mx-2" elevation="0" color="green" @click="dialogEditar = true">Editar</v-btn>
                    <v-btn elevation="0" color="primary" @click="cadastrar()">Cadastrar</v-btn>
                </v-card-title>


                <v-row>
                    <v-col cols="12" md="2">
                        <v-select v-model.number="meta.idempresa" :items="empresasTitulo" item-text="filial"
                            item-value="idfilial" outlined dense label="Cód. Empresa" autofocus
                            class="mb-n5"></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select v-model.number="meta.idservico" :items="servicos" item-text="servico"
                            item-value="idservico" outlined dense label="Cód. Serviço" autofocus
                            class="mb-n5"></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field v-model.number="meta.valmeta" type="number" outlined dense
                            label="Valor da meta (R$)" class="mb-n4"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <InputDatePicker v-model="meta.dtini" label="Data inicial" :outlined="true" :dense="true" />
                    </v-col>
                    <v-col cols="12" md="3">

                        <InputDatePicker v-model="meta.dtfim" label="Data final" :outlined="true" :dense="true" />
                    </v-col>
                </v-row>

            </v-card-text>

        </v-card>

        <v-card class="my-3 primary" dark>

            <v-card-title class="title-servicos white--text">
                Metas Cadastradas
                <v-spacer />
            </v-card-title>

            <v-card-text>
                <v-simple-table dense light>
                    <thead>
                        <tr>
                            <th class="text-center">Cód. Meta</th>
                            <th class="text-center">Cód. Serviço</th>
                            <th class="text-center">Serviço</th>
                            <th class="text-center">Cód. Empresa</th>
                            <th class="text-center">Empresa</th>
                            <th class="text-center text-truncate">Valor Meta</th>
                            <th class="text-center text-truncate">Data Início</th>
                            <th class="text-center text-truncate">Data Fim</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="dados.total > 0">
                            <tr v-for="(m, i) in metas.lista" :key="i">
                                <td class="text-center">{{ m.idmeta }}</td>
                                <td class="text-center">{{ m.idservico }}</td>
                                <td class="text-center">{{ getServicoNome(m.idservico) }}</td>
                                <td class="text-center">{{ m.idempresa }}</td>
                                <td class="text-center">{{ getEmpresaNome(m.idempresa) }}</td>
                                <td class="text-center text-uppercase">{{ m.valmeta.toFixed(2) | formataDinheiro }}
                                </td>
                                <td class="text-center text-uppercase">{{ m.dtini | formataData }}</td>
                                <td class="text-center text-uppercase">{{ m.dtfim | formataData }}</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center" colspan="5">Nenhum registro encontrado.</td>
                            </tr>
                        </template>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>

    </main>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDialog from "../Widgets/CardDialog";
export default {
    name: "ServicosCadastraMeta",
    components: { /*Paginacao,*/CardDialog, InputDatePicker },
    data: () => ({
        erro: null,
        carregando: false,
        carregandoMetas: true,
        dialog: false,
        dialogErro: {
            status: false,
            title: "",
            msg: "",
            icon: "",
            iconColor: "",
        },
        busca: {},
        dados: { lista: [], total: 0 },
        meta: [],
        metaAtualizar: [],
        editandoMeta: false,
        estaDesabilitado: false,
        dialogEditar: false,
        metas: {},
        status: ['1 - Aprovado', '2 - Negado', '3 - Aguardando'],
        statusMeta: [
            { "status": "Ativo", "idstatus": "F" },
            { "status": "Inativo", "idstatus": "T" },
        ],
        empresasTitulo: [
            { "filial": "Ivaiporã", "idfilial": 1 },
            { "filial": "Pitanga", "idfilial": 2 },
            { "filial": "São João do Ivaí", "idfilial": 3 },
            { "filial": "Castelo", "idfilial": 4 },
            { "filial": "Candido", "idfilial": 5 },
            { "filial": "Apucarana", "idfilial": 7 },
            { "filial": "Jardim Alegre", "idfilial": 8 },
            { "filial": "Manoel Ribas", "idfilial": 9 },
            { "filial": "Faxinal", "idfilial": 10 },
            { "filial": "Campo Mourão", "idfilial": 11 },
            { "filial": "Cianorte", "idfilial": 14 },
            { "filial": "Paranavaí", "idfilial": 15 },
            { "filial": "Telêmaco", "idfilial": 16 },
            { "filial": "Roncador", "idfilial": 17 },
            { "filial": "Guarapuava", "idfilial": 18 },
            { "filial": "Palmital", "idfilial": 19 },
            { "filial": "Ibiporã", "idfilial": 20 },
            { "filial": "Turvo", "idfilial": 21 },
            { "filial": "São Mateus do Sul", "idfilial": 23 },
            { "filial": "São João do Triunfo", "idfilial": 24 },
            { "filial": "Pontal do Paraná", "idfilial": 26 },
            { "filial": "Mandaguari", "idfilial": 27 },
            { "filial": "Astorga", "idfilial": 28 },
            { "filial": "Cambé", "idfilial": 30 },
            { "filial": "Reserva", "idfilial": 31 },
            { "filial": "Rolândia", "idfilial": 35 },
            { "filial": "Maringá", "idfilial": 36 },
            { "filial": "Laranjeiras", "idfilial": 38 },
            { "filial": "E-commerce - CD", "idfilial": 39 }
        ],
        servicosTitulo: [
            { "nome": "Empréstimo Pessoal", "idservico": 1 },
            { "nome": "Consignado", "idservico": 21 },
            { "nome": "Crédito Rural", "idservico": 22 },
            { "nome": "FGTS", "idservico": 23 }
        ],
        servicos: [
            { "servico": "1 - Empréstimo Pessoal", "idservico": 1 },
            { "servico": "21 - Consignado", "idservico": 21 },
            { "servico": "22 - Crédito Rural", "idservico": 22 },
            { "servico": "23 - FGTS", "idservico": 23 }
        ],

    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        cancelar() {
            this.metaAtualizar = {};
            this.dialogEditar = false;
        },
        cadastrar() {
            if (
                !this.meta.idempresa ||
                !this.meta.valmeta ||
                !this.meta.dtini ||
                !this.meta.dtfim
            ) {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg =
                    "Um ou mais campos não foram preenchidos!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                return;
            }
            if (this.meta.valmeta <= 0) {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg =
                    "O valor da meta deve ser um valor maior que 0!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                return;
            }

            this.carregando = true;
            return axios
                .post(`${this.backendUrl}metas/cadastro/meta`, {
                    ...this.meta,
                })
                .then((res) => {
                    this.carregando = false;
                    if (res.data == true) {
                        this.dialogErro.title = "Sucesso";
                        this.dialogErro.msg =
                            "Meta cadastrada com sucesso!";
                        this.dialogErro.icon = "mdi-check-circle-outline";
                        this.dialogErro.iconColor = "success";
                        this.dialogErro.status = true;
                        this.meta = [];
                        this.listarMetas();
                    } else {
                        this.dialogErro.title = "Erro";
                        this.dialogErro.msg = "Erro ao cadastrar meta!";
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                })
                .catch(() => {
                    this.dialogErro.title = "Erro";
                    this.dialogErro.msg = "Erro ao cadastrar meta!";
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                    this.carregando = false;
                });

        },
        editar() {
            if (
                !this.metaAtualizar.idmeta ||
                !this.metaAtualizar.idempresa ||
                !this.metaAtualizar.valmeta ||
                !this.metaAtualizar.flaginativo ||
                !this.metaAtualizar.dtini ||
                !this.metaAtualizar.dtfim
            ) {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg =
                    "Um ou mais campos não foram preenchidos!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                return;
            }
            if (this.metaAtualizar.valmeta <= 0) {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg =
                    "O valor da meta deve ser um valor maior que 0!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                return;
            }

            this.carregando = true;
            return axios
                .post(`${this.backendUrl}metas/editar`, {
                    ...this.metaAtualizar,
                })
                .then((res) => {
                    this.carregando = false;
                    if (res.data == true) {
                        this.dialogErro.title = "Sucesso";
                        this.dialogErro.msg =
                            "Meta alterada com sucesso!";
                        this.dialogErro.icon = "mdi-check-circle-outline";
                        this.dialogErro.iconColor = "success";
                        this.dialogErro.status = true;
                        this.metaAtualizar = {
                            "dtini": '',
                            "dtfim": ''
                        }
                        this.listarMetas();
                    } else {
                        this.dialogErro.title = "Erro";
                        this.dialogErro.msg = "Erro ao alterar meta!";
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                })
                .catch(() => {
                    this.dialogErro.title = "Erro";
                    this.dialogErro.msg = "Erro ao alterar meta!";
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                    this.carregando = false;
                });

        },
        listarMetas() {
            return axios
                .post(`${this.backendUrl}metas/listar`, {

                })
                .then((res) => {
                    this.dados = res.data;
                    this.metas = res.data;
                    this.carregandoMetas = false;
                });
        },
        get() {
            if (!this.metaAtualizar.idmeta) {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Código identificador não informado!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                return;
            }
            this.erro = null;
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}metas/cadastro/metaget`, {
                    idmeta: this.metaAtualizar.idmeta || null
                })
                .then((res) => {
                    this.metaAtualizar = {};
                    this.metaAtualizar = res.data;
                    if (this.metaAtualizar.idusuarioalteracao != 0) {
                        this.dialogErro.title = "Alerta!";
                        this.dialogErro.msg = "Esta proposta já foi atualizada!";
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "orange";
                        this.dialogErro.status = true;
                    }
                    this.estaDesabilitado = true;
                    this.carregando = false;
                })
                .catch(() => {
                    this.metaAtualizar.idmeta = 0;
                    this.dialogErro.title = "Erro";
                    this.dialogErro.msg = "Proposta não encontrada!";
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                    this.carregando = false;
                });
        },
        getEmpresaNome(idempresa) {
            const empresa = this.empresasTitulo.find(e => e.idfilial === idempresa);
            return empresa ? empresa.filial : 'Desconhecido';
        },
        getServicoNome(idservico) {
            const servico = this.servicosTitulo.find(e => e.idservico === idservico);
            return servico ? servico.nome : 'Desconhecido';
        },

        async init() {
            this.busca.idfilial = this.usuario.idfilial;
            const json = localStorage.getItem("filtros_contratos");
            const filtro = JSON.parse(json);
            if (filtro) {
                this.busca = filtro;
            }
            await this.listarMetas();
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
.card-metas {
    border: 2px solid #023449;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
</style>